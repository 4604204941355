<template>
  <div v-if="isReady" class="theme-default">
    <div class="flex flex-col w-screen h-screen overflow-hidden bg-gray-100">
      <div class="px-4 pt-4 bg-white shadow flex-0">
        <div class="flex items-center justify-between pb-4 text-xs">
          <div class="flex items-center space-x-1">
            <FontAwesomeIcon :icon="['far', 'user']" />
            <span>{{ $t("Common.General.LoggedInAs") }} {{ user.name }}</span>
          </div>
          <a class="underline" href="#" @click.prevent="logout">{{
            $t("Common.General.Logout")
          }}</a>
        </div>
        <h1 class="pb-4 text-xl font-semibold">
          {{ $t("PalletReceipts.PalletReceipts") }}
        </h1>
        <PTabs>
          <PTab
            :value="$route.name"
            :name="palletReceiptsRouteName"
            @input="navigate($event)"
            >{{ $t("PalletReceipts.NewPalletReceipt") }}</PTab
          >
          <PTab
            :value="$route.name"
            :name="palletReceiptsLogRouteName"
            @input="navigate($event)"
            >{{ $t("PalletReceipts.LatestPalletReceipts") }}</PTab
          >
        </PTabs>
      </div>
      <div class="flex-1 overflow-y-auto">
        <router-view></router-view>
      </div>
    </div>
    <PNotifications :duration="1500"></PNotifications>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { some } from "lodash";
import PNotifications from "@/components/PNotifications";
export default {
  components: {
    PNotifications,
  },
  props: {
    inv: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      "customer",
      "errors",
      "isReady",
      "language",
      "user",
      "webtaskUsers",
    ]),
    palletReceiptsRouteName () {
      return this.inv ? "palletReceiptsInv" : "palletReceipts";
    },
    palletReceiptsLogRouteName () {
      return this.inv ? "palletReceiptsLogInv" : "palletReceiptsLog";
    }
  },
  methods: {
    activeTab(name) {
      return this.$route.name === name;
    },
    navigate(name) {
      this.$router.push({ name });
    },
    async logout() {
      await this.$store.dispatch("logout");
    },
  },
  async created() {
    await this.$store.dispatch("init");

    var userHasAccess = some(this.webtaskUsers, (x) => x.taskId === "SHIPMENT");
    if (userHasAccess && this.errors.length === 0) {
      this.$i18n.locale = this.language;
      this.$store.commit("setIsReady", true);
    }
  },
};
</script>
